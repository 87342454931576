import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { FormTextField } from 'components/form';
import Button from 'components/controls/button/Button';

import { RegisterParameters } from 'types/auth';

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  min-width: 16rem;

  & .MuiTextField-root,
  .MuiButton-root {
    margin-top: 1.5rem;
  }

  & a {
    margin-top: 0.5rem;
  }
`;

export type RegistrationFormParameters = Omit<RegisterParameters, 'token'> & {
  confirmPassword: string;
};

type CompleteRegistrationFormProps = {
  email: string;
  onSetPassword: (parameters: RegistrationFormParameters) => void;
};

const CompleteRegistrationForm: FC<CompleteRegistrationFormProps> = ({
  email,
  onSetPassword,
}): ReactElement => {
  const { t } = useTranslation('common');

  const initialValues: RegistrationFormParameters = {
    email: email,
    newPassword: '',
    confirmPassword: '',
  };

  const completeRegistrationSchema: yup.SchemaOf<RegistrationFormParameters> = yup.object(
    {
      email: yup
        .string()
        .email(t('common:Errors.NotValidEmail'))
        .max(254, t('common:Errors.MaxCharacters', { count: 254 }))
        .required(t('Errors.Required')),
      newPassword: yup
        .string()
        .min(6, t('CompleteRegistration.PasswordMinLength', { length: 8 }))
        .required(t('CompleteRegistration.PasswordIsRequired')),
      confirmPassword: yup
        .string()
        .when('newPassword', {
          is: (val: string) => (val && val.length > 0 ? true : false),
          then: yup
            .string()
            .oneOf(
              [yup.ref('newPassword')],
              t('CompleteRegistration.PasswordsNeedToBeSameError')
            ),
        })
        .required(t('CompleteRegistration.PasswordIsRequired')),
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={completeRegistrationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSetPassword}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <FormTextField
            name="email"
            label={t('CompleteRegistration.EmailLabel')}
            size="small"
            inputProps={{
              readOnly: true,
            }}
          />
          <FormTextField
            name="newPassword"
            label={t('CompleteRegistration.PasswordLabel')}
            type="password"
            size="small"
            autoComplete="new-password"
          />
          <FormTextField
            name="confirmPassword"
            label={t('CompleteRegistration.RepeatPasswordLabel')}
            type="password"
            size="small"
            autoComplete="new-password"
          />
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {t('CompleteRegistration.SetPasswordButton')}
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
};

export default CompleteRegistrationForm;
