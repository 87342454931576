import { format as formatDate } from 'date-fns';
import { enGB, hr, sr } from 'date-fns/locale';
// import i18n from 'i18next';

export {
  parseISO,
  isValid,
  formatISO,
  isWithinInterval,
  isSameDay,
  startOfDay,
  isBefore,
  isAfter,
} from 'date-fns';

export const dateLocales: { [id: string]: Locale } = {
  en: enGB,
  hr: hr,
  sr: sr,
};

export const dateTimeFormat = 'd.M.yyyy. H:mm:ss';

const dateFormat = 'd.M.yyyy';

//delete unused date translation
export const format = (
  date: number | Date,
  format: string = dateFormat
): string => {
  // return formatDate(date, format, {
  //   locale: dateLocales[i18n.languages[0]],
  // });
  return formatDate(date, format);
};

export const getDateFormatString = (): string => {
  // return dateLocales[i18n.languages[0]].formatLong?.date({ width: 'short' });
  return 'dd.MM.yyyy';
};

export const getDateTimeFormatString = (): string => {
  return 'dd.MM.yyyy HH:mm';
};
