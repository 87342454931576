import { FC, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import { SelectOption } from 'components/controls/select/Select';
import { useInstrumentNormsList } from 'containers/customer/hooks/useInstrumentNormsList';
import { FormSelect } from 'components/form';

type FormSelectInstrumentNormsProps = {
  name: string;
  instrumentId: number;
  languageId: string;
  disabled?: boolean;
  onChange?: () => void;
  onClick?: () => void;
  withLabel?: boolean;
};

const FormInstrumentNormsSelect: FC<FormSelectInstrumentNormsProps> = ({
  name,
  instrumentId,
  languageId,
  disabled,
  onChange,
  onClick,
  withLabel,
}): ReactElement => {
  const { t } = useTranslation(['common', 'customer']);
  const { isLoading, norms } = useInstrumentNormsList(instrumentId, languageId);
  const normOptions = norms
    ? norms.map((norm) => ({ value: norm.id, name: norm.name }))
    : [];

  const [, meta, helpers] = useField<SelectOption['value']>(name);

  const { setValue } = helpers;
  const { value } = meta;

  useEffect(() => {
    if (
      !disabled &&
      norms?.length &&
      (value === '' || !norms.some((n) => n.id === Number(value)))
    ) {
      setValue(norms[0].id);
    }
  }, [norms, value, disabled, setValue, name, instrumentId, languageId]);

  return (
    <FormSelect
      onClick={onClick}
      disabled={disabled || !(norms && instrumentId) || isLoading}
      name={name}
      label={
        withLabel
          ? isLoading && instrumentId
            ? t('common:Loading') + '...'
            : t('customer:Batches.Form.Norm')
          : undefined
      }
      size="small"
      options={normOptions}
      onChange={onChange}
    />
  );
};

export default FormInstrumentNormsSelect;
