import { FC, ReactElement } from 'react';
import { useField } from 'formik';

import Select, {
  SelectOption,
  SelectProps,
} from 'components/controls/select/Select';

const FormSelect: FC<
  { name: string; onChange?: () => void } & Omit<
    SelectProps,
    'name' | 'value' | 'onBlur' | 'onChange' | 'multiple'
  >
> = ({ name, onChange, ...rest }): ReactElement => {
  const [, meta, helpers] = useField<SelectOption['value']>(name);

  const { setValue, setTouched } = helpers;
  const { error, touched, value } = meta;

  return (
    <Select
      {...rest}
      value={value}
      onChange={(val) => {
        setValue(val);
        onChange && onChange();
      }}
      onBlur={() => setTouched(true)}
      id={`form-field-${name}`}
      multiple={false}
      error={Boolean(touched && error)}
      helperText={touched && error}
    />
  );
};

export default FormSelect;
