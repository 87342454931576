import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import { colors } from 'styles';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const dotPulse = keyframes`
  0% {
    box-shadow: 9999px 0 0 -0.5rem ${colors.primary};
  }
  30% {
    box-shadow: 9999px 0 0 0.25rem ${colors.primary};
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -0.5rem ${colors.primary};
  }
`;

const dotPulseBefore = keyframes`
  0% {
    box-shadow: calc(9999px - 3rem) 0 0 -0.5rem ${colors.primary};
  }
  30% {
    box-shadow: calc(9999px - 3rem) 0 0 0.25rem ${colors.primary};
  }
  60%,
  100% {
    box-shadow: calc(9999px - 3rem) 0 0 -0.5rem ${colors.primary};
  };
`;

const dotPulseAfter = keyframes`
  0% {
    box-shadow: calc(9999px + 3rem) 0 0 -0.5rem ${colors.primary};
  }
  30% {
    box-shadow: calc(9999px + 3rem) 0 0 0.25rem ${colors.primary};
  }
  60%,
  100% {
    box-shadow: calc(9999px + 3rem) 0 0 -0.5rem ${colors.primary};
  }
`;

const DotPulse = styled.div`
  position: relative;
  left: -9999px;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background-color: ${colors.primary};
  color: ${colors.primary};
  box-shadow: 9999px 0 0 -0.5rem ${colors.primary};
  animation: ${dotPulse} 1.5s infinite linear;
  animation-delay: 0.25s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: ${colors.primary};
    color: ${colors.primary};
  }

  &::before {
    box-shadow: calc(9999px - 3rem) 0 0 -0.5rem ${colors.primary};
    animation: ${dotPulseBefore} 1.5s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    box-shadow: calc(9999px + 3rem) 0 0 -0.5rem ${colors.primary};
    animation: ${dotPulseAfter} 1.5s infinite linear;
    animation-delay: 0.5s;
  }
`;

const FullScreenLoading: FC = () => {
  return (
    <Wrapper>
      <DotPulse />
    </Wrapper>
  );
};

export default FullScreenLoading;
