import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { colors, fontSize } from 'styles';

const StyledLink = styled.a`
  color: ${colors.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  text-decoration: none;
`;

const CommonFooter = styled(({ ...rest }) => {
  return (
    <footer {...rest}>
      <Trans i18nKey={'Footer.CopyrightText'} ns="common">
        Copyright © 2021{' '}
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.nakladaslap.com"
        >
          Naklada Slap
        </StyledLink>
        , privately owned and operated since 1985. All rights reserved.
      </Trans>
    </footer>
  );
})`
  background-color: ${colors.footer};
  font-size: ${fontSize.mainText};
  padding: 0.25rem;
  text-align: center;
`;

export default CommonFooter;
