import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from 'formik';
import styled from 'styled-components';

import { FormTextField } from 'components/form';
import Button from 'components/controls/button/Button';

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  min-width: 16rem;

  & .MuiTextField-root,
  .MuiButton-root {
    margin-top: 1.5rem;
  }

  & a {
    margin-top: 0.5rem;
  }
`;

export type SetNewPasswordFormParameters = {
  newPassword: string;
};

type SetNewPasswordFormProps = {
  onSetPassword: (parameters: SetNewPasswordFormParameters) => void;
};

const SetNewPasswordForm: FC<SetNewPasswordFormProps> = ({
  onSetPassword,
}): ReactElement => {
  const { t } = useTranslation('common');

  const initialValues: SetNewPasswordFormParameters = {
    newPassword: '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSetPassword}>
      {({ isSubmitting }) => (
        <StyledForm>
          <FormTextField
            name="newPassword"
            label={t('RecoverPassword.NewPasswordLabel')}
            type="password"
            size="small"
            autoComplete="new-password"
          />
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {t('RecoverPassword.ChangePasswordButton')}
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
};

export default SetNewPasswordForm;
