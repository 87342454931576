const uiKitColors = {
  backgroundBlue: '#e6eff6',
  primaryBlue: '#009fdb',
  darkBlue: '#033888',
  lightBlue: '#f5fafd',
  primaryWhite: '#ffffff',
  lightWhite: 'rgba(255, 255, 255, 0.7)',
  red: '#ff505f',
  lightRed: '#fff1f2',
  darkGrey: '#8e97a4',

  primaryGrey: '#797e8a',
  secondaryGrey: '#a0a3aa',
  borderGrey: '#C9CBD2',

  black: '#253343',
};

export const colors = {
  appBackground: uiKitColors.backgroundBlue,
  mainText: uiKitColors.primaryGrey,
  headerText: uiKitColors.black,
  secondaryText: uiKitColors.secondaryGrey,
  footer: '#f5fafd',

  background: uiKitColors.primaryWhite,
  primary: uiKitColors.darkBlue,
  error: uiKitColors.red,
  warning: '#ff9800',
  disabled: '#e4e6eb',

  accentBackground: uiKitColors.darkBlue,
  accentPrimaryText: uiKitColors.primaryWhite,
  accentSecondaryText: uiKitColors.lightWhite,

  primaryButton: {
    background: uiKitColors.darkBlue,
    text: uiKitColors.primaryWhite,
    hover: '#042558',
  },
  secondaryButton: {
    text: uiKitColors.primaryWhite,
  },
  tertiaryButton: {
    background: 'rgba(0, 159, 219, 0.1)',
    text: uiKitColors.primaryBlue,
    hover: '#c1ecfc',
  },
  textButton: {
    text: uiKitColors.primaryBlue,
    hoverText: uiKitColors.primaryBlue,
  },
  danger: {
    background: uiKitColors.primaryWhite,
    text: uiKitColors.red,
    border: uiKitColors.red,
    hover: uiKitColors.red,
    hoverText: uiKitColors.primaryWhite,
  },

  lightBorder: '#d8e7f2',
  sideMenuHeaderBorder: '#ffffff19',

  table: {
    background: uiKitColors.lightBlue,
    headerText: uiKitColors.darkGrey,
  },

  tag: {
    background: '#eef5fb',
    color: uiKitColors.darkBlue,

    filter: {
      border: uiKitColors.borderGrey,
      color: uiKitColors.primaryGrey,
      focus: '#f0f0f0',
    },
  },

  decorativeShadow: {
    firstLayer: '#cbe1f1',
    secondLayer: '#cbe1f17f',
  },

  checkbox: {
    color: uiKitColors.primaryGrey,
    checked: uiKitColors.darkBlue,
  },

  menu: {
    border: uiKitColors.borderGrey,
    shadow: '#e0e0e0',
  },

  tab: {
    color: uiKitColors.primaryBlue,
    border: uiKitColors.primaryBlue,
    selectedBackground: uiKitColors.primaryBlue,
  },

  editor: {
    color: uiKitColors.primaryGrey,
    border: uiKitColors.borderGrey,
    hoverBorder: uiKitColors.darkBlue,
    placeholder: 'rgba(0, 0, 0, 0.54)',
  },

  optionList: {
    border: uiKitColors.borderGrey,
    hoverBorder: uiKitColors.darkBlue,
    hoverBackground: '#009fdb1a',
  },

  icons: {
    dashboard: '#E3D500',
    respondents: '#965AC5',
    sessions: '#D59726',
    results: '#d33f21',
    credits: '#28bdbb',
    batches: '#78c583',
    templates: '#62a0fb',
    users: '#b995f9',
    manual: '#03aae9',
    tags: '#ED6E78',
  },

  progress: {
    background: '#009fdb1a',
    value: '#60d46b',
  },

  questionList: {
    active: uiKitColors.primaryBlue,
    background: '#009fdb1a',
    activeText: uiKitColors.primaryWhite,
    text: uiKitColors.black,
  },

  failConfirmation: {
    image: uiKitColors.primaryBlue,
  },
};
