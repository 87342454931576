import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomerRolesList } from 'containers/customer/hooks/useCustomerRolesList';
import { FormSelect } from 'components/form';

type FormRolesSelectProps = {
  name: string;
  disabled?: boolean;
};

const FormCustomerRolesSelect: FC<FormRolesSelectProps> = ({
  name,
  disabled,
}): ReactElement => {
  const { t } = useTranslation(['common', 'customer']);
  const { isLoading, roles } = useCustomerRolesList();

  const roleOptions = roles
    ? roles.map((role) => ({
        value: role.id,
        name: t(`common:Roles.${role.name}`),
      }))
    : [];

  return (
    <FormSelect
      disabled={isLoading || disabled}
      name={name}
      label={
        isLoading
          ? t('common:Loading') + '...'
          : t('customer:Users.Form.RoleLabel')
      }
      size="small"
      options={roleOptions}
      required
    />
  );
};

export default FormCustomerRolesSelect;
