import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import { colors } from './colors';
import { fontFamily, fontSize, fontWeight } from './typography';
import { borderRadius, defaultBorder, fieldBorderRadius } from './borders';

import { AlertClassKey } from '@material-ui/lab/Alert';
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
  }
}

const breakpoints = createBreakpoints({});

export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    error: {
      main: colors.error,
    },
  },
  typography: {
    h1: {
      fontSize: fontSize.h1,
      fontWeight: fontWeight.bold,
      color: colors.headerText,
    },
    h2: {
      fontSize: fontSize.h2,
      fontWeight: fontWeight.bold,
    },
    h3: {
      fontSize: fontSize.h3,
      fontWeight: fontWeight.bold,
    },
    h4: {
      fontSize: fontSize.h4,
      fontWeight: fontWeight.bold,
    },
    h5: {
      fontSize: fontSize.h5,
      fontWeight: fontWeight.regular,
    },
    h6: {
      fontSize: fontSize.h6,
      fontWeight: fontWeight.bold,
    },
    subtitle1: {
      fontSize: fontSize.subtitle1,
    },
    body1: {
      fontSize: fontSize.mainText,
    },
    fontFamily,
  },
  props: {
    MuiButton: {
      disableElevation: true,
      variant: 'contained',
    },
    MuiListItemText: {
      disableTypography: true,
    },
    MuiTextField: {
      variant: 'outlined',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        color: colors.mainText,
      },
      rounded: {
        borderRadius: borderRadius,
      },
      elevation2: {
        borderRadius: fieldBorderRadius,
        border: `1px solid ${colors.menu.border}`,
        boxShadow: `0px 1px 5px ${colors.menu.shadow}`,
      },
    },
    MuiTabs: {
      root: {
        color: colors.tab.color,
        minHeight: '2rem',
      },
      flexContainer: {
        flexWrap: 'wrap',
        gap: '0.5rem',
      },
      indicator: {
        backgroundColor: 'transparent',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        borderRadius: '1rem',
        minHeight: '2rem',
        padding: '0 0.875rem',
        border: `1px solid ${colors.tab.border}`,
        letterSpacing: '0.4px',
        fontWeight: fontWeight.bold,
        [breakpoints.up('sm')]: {
          minWidth: '5em',
        },
        '&:hover': {
          opacity: 1,
        },
        '&$selected': {
          background: colors.tab.selectedBackground,
          color: colors.primaryButton.text,
          border: `none`,
        },
      },
    },
    MuiTableRow: {
      root: {
        background: colors.table.background,
        '&:nth-of-type(odd)': {
          background: colors.background,
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '1rem',
        borderBottom: defaultBorder,
      },
      stickyHeader: {
        backgroundColor: colors.table.background,
      },
      head: {
        background: colors.table.background,
        color: colors.table.headerText,
        textTransform: 'uppercase',
        fontSize: fontSize.tableHeader,
        fontWeight: fontWeight.bold,
        letterSpacing: '0.1em',
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0.5rem 1rem',
        '& .MuiFormControlLabel-label': {
          fontSize: fontSize.tableHeader,
        },
      },
      body: {
        color: 'inherit',
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      paddingCheckbox: {
        overflow: 'visible',
      },
    },
    MuiDrawer: {
      paper: {
        color: colors.headerText,
        width: '25rem',
        maxWidth: '100%',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: fontSize.mainText,
      },
    },
    MuiInput: {
      root: {
        fontSize: fontSize.mainText,
      },
      underline: {
        '&:after': {
          borderBottomWidth: '1px',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: colors.primary,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: fontSize.mainText,
        '&:hover:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.primary,
        },
      },
    },
    MuiChip: {
      root: {
        fontSize: fontSize.mainText,
        height: '2em',
      },
      sizeSmall: {
        height: '1.5em',
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        '& .MuiTab-root.Mui-selected': {
          background: 'none',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        '& h4.MuiPickersToolbarText-toolbarTxt': {
          // HACK: month selector font size
          fontSize: fontSize.h1,
        },
        '& h6.MuiPickersToolbarText-toolbarTxt': {
          // HACK: year selector font size
          fontSize: fontSize.h2,
        },
      },
    },
    MuiPickersYear: {
      root: {
        fontSize: fontSize.mainText,
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: colors.danger.text,
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.checkbox.color,
        '&$checked': {
          color: colors.checkbox.checked,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        color: colors.mainText,
      },
    },
    MuiMenuItem: {
      dense: {
        paddingTop: '0.1rem',
        paddingBottom: '0.1rem',
      },
    },
    MuiDialogActions: {
      spacing: {
        justifyContent: 'center',
        padding: '0.5rem 0 2rem',
        gap: '1rem',
        flexWrap: 'wrap-reverse',
        '&>:not(:first-child)': {
          marginLeft: '0',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center',
        paddingTop: '2.5rem',
        fontSize: fontSize.h2,
        color: colors.headerText,
        fontWeight: fontWeight.bold,
        lineHeight: '1.5rem',
      },
    },
    MuiDialogContent: {
      root: {
        textAlign: 'center',
      },
    },
    MuiFormHelperText: {
      marginDense: {
        marginBottom: '-1rem',
        marginTop: '0rem',
        lineHeight: 'normal',
      },
    },
    MuiSnackbar: {
      root: {
        marginBottom: '3rem',
      },
    },
    MuiAlert: {
      standardError: {
        backgroundColor: colors.error,
        color: colors.accentPrimaryText,
        fontWeight: fontWeight.bold,
        '& > .MuiAlert-icon': {
          color: colors.accentPrimaryText,
        },
      },
      standardWarning: {
        backgroundColor: colors.warning,
        color: colors.accentPrimaryText,
        fontWeight: fontWeight.bold,
        '& > .MuiAlert-icon': {
          color: colors.accentPrimaryText,
        },
      },
      message: {
        whiteSpace: 'pre-line',
      },
    },
  },
});
