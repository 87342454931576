import useSWR from 'swr';

import { Norm } from 'types/norm';

export const useInstrumentNormsList = (id: number, languageId: string) => {
  const { data, error } = useSWR<Norm[]>(
    id && languageId ? `/instruments/${id}/norms?lang=${languageId}` : null
  );
  return {
    norms: data,
    isLoading: !data && !error,
  };
};
