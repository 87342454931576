import React, { Suspense } from 'react';
import { SWRConfig } from 'swr';
import { Switch } from 'react-router-dom';

import { useShowAlert } from 'hooks/useShowAlert';
import { fetcher } from 'apis/api';
import { AuthProvider } from 'providers/AuthProvider';
import { UserInfoProvider } from 'providers/UserInfoProvider';
import { paths } from 'constants/paths';

import Login from 'containers/login/Login';
import { ApplicationLayout, AssessmentLayout } from 'containers/Layout';
import Registration from 'containers/registration/Registration';
import CompleteRegistration from 'containers/complete-registration/CompleteRegistration';
import ConfirmEmail from 'containers/confirm-email/ConfirmEmail';

import PrivateRoute from 'components/routes/PrivateRoute';
import PublicRoute from 'components/routes/PublicRoute';
import ForgotPassword from 'containers/forgot-password/ForgotPassword';
import FullScreenLoading from 'components/page/FullScreenLoading';
import useLogin from 'hooks/useLogin';

const AdminRoot = React.lazy(() => import('containers/admin/AdminRoot'));
const CustomerRoot = React.lazy(
  () => import('containers/customer/CustomerRoot')
);
const Assessment = React.lazy(() => import('containers/assessment/Assessment'));

function App() {
  const { showError } = useShowAlert();
  const { logout } = useLogin();

  return (
    <SWRConfig
      value={{
        fetcher: fetcher,
        onError: async (err: Error) => {
          if (err.status === 403) {
            await logout();
            // TODO: Find better solution if possible
            window.location.reload();
          }
          showError(err.message);
        },
      }}
    >
      <Switch>
        <PublicRoute path={paths.app.assessment}>
          <AssessmentLayout>
            <Assessment />
          </AssessmentLayout>
        </PublicRoute>
        <PublicRoute path="/">
          <AuthProvider>
            <UserInfoProvider>
              <ApplicationLayout>
                <Suspense fallback={<FullScreenLoading />}>
                  <Switch>
                    <PublicRoute path={paths.app.login} restrictedForAuth>
                      <Login />
                    </PublicRoute>
                    <PublicRoute
                      path={paths.app.completeRegistration}
                      restrictedForAuth
                    >
                      <CompleteRegistration />
                    </PublicRoute>
                    <PublicRoute
                      path={paths.app.confirmEmail}
                      restrictedForAuth
                    >
                      <ConfirmEmail />
                    </PublicRoute>
                    <PublicRoute
                      path={[
                        paths.app.changePassword,
                        paths.app.forgotPassword,
                      ]}
                      restrictedForAuth
                    >
                      <ForgotPassword />
                    </PublicRoute>
                    <PublicRoute
                      path={paths.app.registration}
                      restrictedForAuth
                    >
                      <Registration />
                    </PublicRoute>
                    <PrivateRoute path={paths.app.admin} role="Administrator">
                      <AdminRoot />
                    </PrivateRoute>
                    <PrivateRoute path={paths.app.customer} role="Customer">
                      <CustomerRoot />
                    </PrivateRoute>
                  </Switch>
                </Suspense>
              </ApplicationLayout>
            </UserInfoProvider>
          </AuthProvider>
        </PublicRoute>
      </Switch>
    </SWRConfig>
  );
}

export default App;
