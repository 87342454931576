import { useContext } from 'react';

import { AuthContext } from 'providers/AuthProvider';

const useCurrentUser = () => {
  const authState = useContext(AuthContext);

  return { user: authState.user };
};

export default useCurrentUser;
