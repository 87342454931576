import { FC } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import useCurrentUser from 'hooks/useCurrentUser';
import { paths } from 'constants/paths';

type PublicRouteProps = RouteProps & {
  redirectPath?: string;
  restrictedForAuth?: boolean;
};

const PublicRoute: FC<PublicRouteProps> = ({
  redirectPath,
  restrictedForAuth,
  ...rest
}) => {
  const { user } = useCurrentUser();

  if (user && restrictedForAuth) {
    const pathByRole =
      user.role === 'Administrator' ? paths.app.admin : paths.app.customer;

    return (
      <Redirect
        to={{
          pathname: redirectPath || pathByRole,
        }}
      />
    );
  } else {
    return <Route {...rest} />;
  }
};

export default PublicRoute;
