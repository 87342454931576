import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Menu } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import { colors, defaultBorder, zIndex } from 'styles';

import Button from 'components/controls/button/Button';
import LanguageSelector from './components/LanguageSelector';
import ProfileButton from './components/ProfileButton';
import { Link, LinkProps } from 'react-router-dom';
import { paths } from 'constants/paths';
import AppLogo from 'components/app-logo/AppLogo';

import useCurrentUserInfo from 'hooks/useCurrentUserInfo';
import { CustomerUserInformation } from 'types/userInfo';

const TopMenuSection = styled.section`
  padding: 0 1rem;
  height: 4.5rem;
  background-color: ${colors.background};
  color: ${colors.mainText};

  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;

  & .MuiInputBase-input {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledSelector = styled(LanguageSelector)`
  color: ${colors.mainText};
  margin-left: auto;

  & .MuiSelect-icon {
    color: ${colors.mainText};
  }
`;

const ButtonSection = styled.div`
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: ${zIndex.overContent};
  padding: 0 1rem 1rem;
  display: flex;
  gap: 0.5rem;
  background-color: ${colors.background};
  border-bottom: ${defaultBorder};
`;

const AppLogoHeader = styled(AppLogo)`
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
`;

const StyledIconButton = styled(IconButton)`
  color: ${colors.textButton.text};
  padding: 0;
`;

const CreateSessionLink = React.forwardRef<any, Omit<LinkProps, 'to'>>(
  (props, ref) => (
    <Link ref={ref} to={paths.customer.createSession} {...props} />
  )
);

const ManualInputLink = React.forwardRef<any, Omit<LinkProps, 'to'>>(
  (props, ref) => <Link ref={ref} to={paths.customer.manualInput} {...props} />
);

const TopMenu = ({ ...rest }) => {
  const { t } = useTranslation(['customer', 'common']);
  const [collapsed, setCollapsed] = useState(true);
  const { user } = useCurrentUserInfo<CustomerUserInformation>();

  return (
    <TopMenuSection {...rest}>
      <AppLogoHeader />
      <StyledSelector collapsed />
      <ProfileButton collapsed />
      {user?.canStartAssessment && (
        <StyledIconButton
          aria-label={t('common:Menu.Menu')}
          onClick={() => setCollapsed((c) => !c)}
        >
          <Menu />
        </StyledIconButton>
      )}
      {!collapsed && (
        <ButtonSection>
          <Button color="primary" component={CreateSessionLink}>
            {t('customer:Common.CreateSessionButtonLabel')}
          </Button>
          <Button color="secondary" component={ManualInputLink}>
            {t('customer:Common.ManualInputButtonLabel')}
          </Button>
        </ButtonSection>
      )}
    </TopMenuSection>
  );
};

export default TopMenu;
