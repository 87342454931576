import { useCallback, useContext } from 'react';
import { AlertContext } from 'providers/AlertProvider';
import { useTranslation } from 'react-i18next';
import { AlertType } from 'types/alerts';

export const useShowAlert = () => {
  const { t } = useTranslation('common');
  const showAlert = useContext(AlertContext);

  const showError = useCallback(
    (message?: any, shouldAutoHide?: boolean) => {
      let errorMessage = null;
      if (message instanceof Error) {
        errorMessage = (message as Error).message;
      } else if (typeof message === 'string') {
        errorMessage = message;
      }
      showAlert(
        errorMessage || t('Errors.General'),
        AlertType.Error,
        shouldAutoHide
      );
    },
    [t, showAlert]
  );

  const showWarning = useCallback(
    (message?: any, shouldAutoHide?: boolean) => {
      let errorMessage = null;
      if (message instanceof Error) {
        errorMessage = (message as Error).message;
      } else if (typeof message === 'string') {
        errorMessage = message;
      }
      showAlert(
        errorMessage || t('Errors.General'),
        AlertType.Warning,
        shouldAutoHide
      );
    },
    [t, showAlert]
  );

  return {
    showError,
    showWarning,
  };
};
