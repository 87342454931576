import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import queryString from 'query-string';

import { colors } from 'styles';
import { paths } from 'constants/paths';
import { ValidationError } from 'types/errors';
import { post } from 'apis/api';

import Button from 'components/controls/button/Button';
import PanePageLayout from 'components/page/PanePageLayout';

const EmailConfirmError = styled.p`
  margin: 0.5rem 0 0;
  text-align: center;
  color: ${colors.error};
`;

const ExplanationText = styled.p`
  margin: 0.5rem 0 1.5rem;
  text-align: center;
`;

const useEmailConfirmationParams = () => {
  const { search } = useLocation();
  const { from, to, token } = queryString.parse(search, {
    decode: false,
  });

  return {
    oldEmail: from as string | null,
    newEmail: to as string | null,
    token: token as string | null,
  };
};

const ConfirmEmail = () => {
  const { t } = useTranslation('common');
  const [emailConfirmError, setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const history = useHistory();

  const { oldEmail, newEmail, token } = useEmailConfirmationParams();

  const handleConfirmEmail = async () => {
    try {
      setError(null);
      setSubmitting(true);

      if (token) {
        await post(`/authentication/confirm-email`, {
          oldEmail,
          newEmail,
          token,
        });

        history.push(paths.app.login);
      }
    } catch (err) {
      let errorMessage;

      if (err instanceof ValidationError) {
        const validationError = err as ValidationError;

        if (validationError.errors.length) {
          errorMessage = t(`ServerErrors.${validationError.errors[0].code}`);
        }
      }

      setError(errorMessage ?? t('ConfirmEmail.FailedToConfirmEmailError'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <PanePageLayout heading={t('ConfirmEmail.Heading')}>
      <ExplanationText>{t('ConfirmEmail.ConfirmEmailText')}</ExplanationText>
      <ExplanationText>
        {t('ConfirmEmail.NewEmailText')}:&nbsp;
        <strong>{newEmail}</strong>
      </ExplanationText>
      <Button
        color="primary"
        type="submit"
        disabled={isSubmitting}
        onClick={handleConfirmEmail}
      >
        {t('ConfirmEmail.ConfirmEmailButton')}
      </Button>
      {emailConfirmError && (
        <EmailConfirmError>{emailConfirmError}</EmailConfirmError>
      )}
    </PanePageLayout>
  );
};

export default ConfirmEmail;
