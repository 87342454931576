import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { colors, fontWeight } from 'styles';
import useLogin from 'hooks/useLogin';
import { Credentials } from 'types/auth';
import { ValidationError } from 'types/errors';
import { paths } from 'constants/paths';

import LoginForm from './LoginForm';
import PanePageLayout from 'components/page/PanePageLayout';

const RegisterLabel = styled.p`
  margin: 0.5rem 0 0;
  text-align: center;

  & a {
    color: ${colors.primary};
    font-weight: ${fontWeight.bold};
    text-decoration: none;
  }
`;

const SignInError = styled.p`
  margin: 0.5rem 0 0;
  text-align: center;
  color: ${colors.error};
`;

const Login = () => {
  const { t } = useTranslation('common');
  const [loginError, setError] = useState<string | null>(null);

  const { login } = useLogin();

  const handleLogin = async (credentials: Credentials) => {
    try {
      setError(null);
      await login(credentials);
    } catch (err) {
      let errorMessage;

      if (err instanceof ValidationError) {
        const validationError = err as ValidationError;

        if (validationError.errors.length) {
          errorMessage = t(`ServerErrors.${validationError.errors[0].code}`);
        }
      }

      setError(errorMessage ?? t('Errors.FailedToLogIn'));
    }
  };

  return (
    <PanePageLayout heading={t('Login.WelcomeMessage')}>
      <LoginForm onLogin={handleLogin} />
      {loginError && <SignInError>{loginError}</SignInError>}
      <RegisterLabel>
        {t('Login.FirstTimeLabel')}&nbsp;
        <Link to={paths.app.registration}>{t('Login.RegisterLink')}</Link>
      </RegisterLabel>
    </PanePageLayout>
  );
};

export default Login;
