import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Formik, Form as FormikForm } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { FormTextField } from 'components/form';
import Button from 'components/controls/button/Button';

import { Credentials } from 'types/auth';
import { paths } from 'constants/paths';

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  min-width: 16rem;

  & .MuiTextField-root,
  .MuiButton-root {
    margin-top: 1.5rem;
  }

  & a {
    margin-top: 0.5rem;
    color: inherit;
    align-self: flex-start;
  }
`;

type LoginFormProps = {
  onLogin: (credentials: Credentials) => void;
};

const LoginForm: FC<LoginFormProps> = ({ onLogin }): ReactElement => {
  const { t } = useTranslation('common');

  const initialValues: Credentials = {
    username: '',
    password: '',
  };

  const loginSchema: yup.SchemaOf<Credentials> = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={loginSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onLogin}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <FormTextField
            name="username"
            label={t('Login.EmailLabel')}
            silentErrors
            size="small"
            autoComplete="email"
          />
          <FormTextField
            name="password"
            label={t('Login.PasswordLabel')}
            type="password"
            silentErrors
            size="small"
            autoComplete="current-password"
          />
          <Link to={paths.app.forgotPassword}>
            {t('Login.ForgotPasswordLink')}
          </Link>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {t('Login.LoginButton')}
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
};

export default LoginForm;
