import { useContext } from 'react';

import { UserInfoContext } from 'providers/UserInfoProvider';
import {
  AdministratorInformation,
  BaseInformation,
  CustomerUserInformation,
} from 'types/userInfo';

const useCurrentUserInfo = <
  T extends CustomerUserInformation | AdministratorInformation | BaseInformation
>() => {
  const userInfo = useContext(UserInfoContext);

  return { user: null === userInfo ? null : (userInfo as T) };
};

export default useCurrentUserInfo;
