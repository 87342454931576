import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FormTextField, FormSelect } from 'components/form';
import Button from 'components/controls/button/Button';
import Progress from 'components/controls/progress/Progress';

import { Education } from 'types/education';
import { enumKeysAsTranslatedSelectOptions } from 'utils/enumFunctions';

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 1rem 0 1rem;
  position: relative;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  & :nth-child(1) {
    flex: 1;
  }
  & :nth-child(2) {
    margin-left: 1rem;
    flex: 1;
  }
  padding: 0 1rem 0 1rem;
`;

type RegistrationUserFormProps = {
  onBackClick(): void;
  isSubmitting: boolean;
};

const RegistrationUserForm: FC<RegistrationUserFormProps> = ({
  onBackClick,
  isSubmitting,
}): ReactElement => {
  const { t } = useTranslation('common');

  const educationOptions = enumKeysAsTranslatedSelectOptions(
    Education,
    (key: string) => t(`Education.${key}`)
  );

  return (
    <>
      <InputSection>
        {isSubmitting && <Progress overlay />}
        <FormTextField
          required
          name="adminUser.firstName"
          label={t('Registration.FirstNameLabel')}
          size="small"
        />
        <FormTextField
          required
          name="adminUser.lastName"
          label={t('Registration.LastNameLabel')}
          size="small"
        />
        <FormTextField
          required
          name="adminUser.email"
          label={t('Registration.UserEmailLabel')}
          size="small"
        />
        <FormTextField
          name="adminUser.phone"
          label={t('Registration.UserPhoneLabel')}
          size="small"
        />
        <FormTextField
          name="adminUser.occupation"
          label={t('Registration.OccupationLabel')}
          size="small"
        />
        <FormTextField
          name="adminUser.workingPlace"
          label={t('Registration.WorkingPlaceLabel')}
          size="small"
        />
        <FormSelect
          name="adminUser.education"
          required
          label={t('Registration.EducationLabel')}
          options={educationOptions}
          size="small"
        />
        <FormTextField
          name="adminUser.graduatedFrom"
          label={t('Registration.GraduatedFromLabel')}
          size="small"
        />
        <FormTextField
          name="adminUser.graduationYear"
          label={t('Registration.GraduationYearLabel')}
          size="small"
        />
        <FormTextField
          name="adminUser.contractNumber"
          label={t('Registration.ContractNumberLabel')}
          size="small"
        />
        <FormTextField
          name="adminUser.nakladaSlapId"
          label={t('Registration.NakladaSlapIdLabel')}
          size="small"
        />
      </InputSection>
      <ButtonSection>
        <Button color="tertiary" onClick={onBackClick}>
          {t('Registration.BackButtonLabel')}
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          {t('Registration.RegisterButtonLabel')}
        </Button>
      </ButtonSection>
    </>
  );
};

export default RegistrationUserForm;
