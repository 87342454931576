import { FC, ReactElement } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import { Tag } from 'types/tags';

import TagComponent from '../tag/Tag';
import { useTranslation } from 'react-i18next';

export type TagMultiselectProps = {
  value: Tag[];
  options: Tag[];
  onChange(tags: Tag[]): void;

  id?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  size?: 'medium' | 'small';
  label?: string;
  onBlur?(): void;
  freeTagInput?: boolean;
};

const TagMultiselect: FC<TagMultiselectProps> = ({
  value,
  options,
  onChange,

  id,
  variant,
  size,
  label,
  onBlur,
  freeTagInput = false,
}): ReactElement => {
  const { t } = useTranslation('common');

  return (
    <Autocomplete
      multiple
      onBlur={onBlur}
      id={id}
      options={options}
      size={size}
      getOptionLabel={(option) => option.name}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} variant={variant} label={label} />
      )}
      renderTags={(value, getTagProps) =>
        value.map((tagValue, index) => (
          <TagComponent name={tagValue.name} {...getTagProps({ index })} />
        ))
      }
      value={value}
      onChange={(event, value) => {
        onChange(
          value.map((val) =>
            typeof val === 'string'
              ? ({ id: 0, name: val } as Tag)
              : (val as Tag)
          )
        );
      }}
      disableClearable
      openText={t('Tags.OpenTagsListButton')}
      noOptionsText={t('Tags.NoTagsText')}
      freeSolo={freeTagInput}
    />
  );
};

export default TagMultiselect;
