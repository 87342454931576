export enum ErrorCodes {
  ValidationError = 400,
  Forbidden = 403,
}

interface ValidationParameterDictionary {
  [param: string]: string;
}

interface ValidationErrorType {
  code: string;
  propertyName?: string;
  parameters?: ValidationParameterDictionary;
}

export class ValidationError extends Error {
  errors: ValidationErrorType[];

  constructor(message?: string) {
    super(message);

    this.errors = [];
  }
}

export enum Severity {
  Error,
  Warning,
}

export interface ImportValidationError {
  severity: Severity;
  code: string;
  path?: string;
  languageId?: string;
  message?: string;
}
