import { FC, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MenuItem, Select } from '@material-ui/core';
import { colors } from 'styles';
import { supportedLngs } from 'constants/languages';
import useCurrentInstance from 'hooks/useCurrentInstance';

const StyledSelect = styled(Select)`
  color: ${colors.accentPrimaryText};

  & .MuiSelect-icon {
    color: ${colors.accentPrimaryText};
  }

  &:before {
    display: none;
  }
`;

type LanguageSelectorProps = {
  collapsed: boolean;
  children?: ReactNode;
};

const LanguageSelector: FC<LanguageSelectorProps> = ({
  collapsed,
  ...props
}): ReactElement => {
  const { i18n } = useTranslation();
  const { instanceInfo } = useCurrentInstance();

  const supportedInstanceLanguages =
    instanceInfo && instanceInfo.availableLanguages
      ? supportedLngs.filter((lng) =>
          instanceInfo.availableLanguages.includes(lng.code)
        )
      : [supportedLngs[0]];

  const handleLanguageChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    i18n.changeLanguage(event.target.value as string);
  };

  return (
    <StyledSelect
      value={i18n.languages[0]}
      onChange={handleLanguageChange}
      {...props}
    >
      {supportedInstanceLanguages.map((lang) => (
        <MenuItem key={lang.code} value={lang.code}>
          {collapsed ? lang.name.substring(0, 2).toUpperCase() : lang.name}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default LanguageSelector;
