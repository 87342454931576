import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Menu, MenuItem, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

import useCurrentUser from 'hooks/useCurrentUser';
import useCurrentUserInfo from 'hooks/useCurrentUserInfo';
import useLogin from 'hooks/useLogin';

import { useShowAlert } from 'hooks/useShowAlert';
import { BaseInformation } from 'types/userInfo';
import { paths } from 'constants/paths';

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;

  &:focus {
    background-color: rgba(0, 0, 0, 0.08);
  }

  & * ~ * {
    margin-left: 0.5rem;
  }

  & .MuiTypography-root {
    word-break: break-word;
  }
`;

const ProfileButton: FC<{ collapsed: boolean }> = ({
  collapsed,
  ...props
}): ReactElement => {
  const { t } = useTranslation('common');
  const { user } = useCurrentUser();
  const { user: userInfo } = useCurrentUserInfo<BaseInformation>();
  const { logout } = useLogin();
  const { showError } = useShowAlert();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleLogout = async () => {
    try {
      setAnchorEl(null);
      await logout();
    } catch (e) {
      showError();
    }
  };

  const openProfilePage = () => {
    history.push(
      user?.role === 'Customer' ? paths.customer.profile : paths.admin.profile
    );
    setAnchorEl(null);
  };

  return (
    <>
      <ProfileWrapper
        role="button"
        aria-controls="profile-menu"
        aria-haspopup="true"
        aria-label={t('Login.OpenProfileMenuLabel')}
        tabIndex={0}
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
        onKeyDown={(evt) =>
          evt.key === 'Enter' && setAnchorEl(evt.currentTarget)
        }
        {...props}
      >
        <AccountCircle />
        {!collapsed && userInfo && (
          <Typography
            variant="body1"
            component="span"
          >{`${userInfo.firstName} ${userInfo.lastName}`}</Typography>
        )}
      </ProfileWrapper>
      <Menu
        id="profile-menu"
        elevation={2}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {history.location.pathname !== paths.admin.profile &&
        history.location.pathname !== paths.customer.profile ? (
          <MenuItem onClick={openProfilePage}>
            {t('Profile.PersonalInformation')}
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleLogout}>{t('Login.LogoutButton')}</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileButton;
