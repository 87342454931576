import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { colors, defaultBorder, fieldBorderRadius } from 'styles';

import { Chip, ChipProps } from '@material-ui/core';

const StyledChip = styled(Chip)`
  color: ${colors.tag.color};
  background: ${colors.tag.background};
  border: ${defaultBorder};
  border-radius: ${fieldBorderRadius};
  max-width: 100%;

  & .MuiChip-deleteIcon {
    color: ${colors.primary};
  }
`;

type TagProps = {
  name: string;
};

const Tag: FC<TagProps & ChipProps> = ({ name, ...rest }): ReactElement => {
  return <StyledChip label={name} size="small" {...rest} />;
};

export default Tag;
