export const trimObjectProperties = (obj: any) => {
  const trimmedObject = { ...obj };

  Object.keys(trimmedObject).forEach((prop) => {
    if (typeof trimmedObject[prop] === 'string')
      trimmedObject[prop] = trimmedObject[prop].trim();
  });

  return trimmedObject;
};
