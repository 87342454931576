import { ReactNode } from 'react';
import styled from 'styled-components';

import { Paper, Typography } from '@material-ui/core';

import { colors, fontWeight, fontSize } from 'styles';
import AppLogo from 'components/app-logo/AppLogo';

const Logo = styled(AppLogo)``;

const LoginHeader = styled.header`
  & ${Logo} {
    display: inline-flex;
    padding: 1rem;
  }

  background: ${colors.accentBackground};
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden auto;
  padding: 1rem;
`;

const PageContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 2rem 1rem 1.5rem 1rem;
  font-size: ${fontSize.mainText};
  min-height: 0;
  min-width: 30%;

  & .MuiTypography-h1 {
    font-weight: ${fontWeight.regular};
    text-align: center;
  }
`;

const PanePageLayout = ({
  heading,
  children,
  ...rest
}: {
  heading?: string;
  children: ReactNode;
}) => {
  return (
    <>
      <LoginHeader>
        <Logo />
      </LoginHeader>
      <ContentWrapper>
        <PageContainer elevation={0} component="section" square {...rest}>
          {heading && <Typography variant="h1">{heading}</Typography>}
          {children}
        </PageContainer>
      </ContentWrapper>
    </>
  );
};

export default PanePageLayout;
