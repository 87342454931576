import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { List, ListItem, ListItemIcon } from '@material-ui/core';

import { colors } from 'styles';
import { NavigationLink } from 'types/navigationLink';

import SideMenuLink from './components/SideMenuLink';
import MoreMenuItems from './components/MoreMenuItems';

const Footer = styled.footer`
  height: 3.5rem;
  background-color: ${colors.accentBackground};
  color: ${colors.accentPrimaryText};

  display: flex;
  align-items: center;
`;

const NavigationMenuList = styled(List)`
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;

  display: flex;
  justify-content: space-around;

  & .MuiListItem-root {
    width: auto;
    padding: 0.75rem;
  }

  & .MuiListItemIcon-root {
    min-width: 1.5rem;
  }
`;

const FooterMenu = ({
  navigationLinks,
  ...rest
}: {
  navigationLinks: NavigationLink[];
}) => {
  useTranslation(['customer', 'common']); // HACK: verify that translations loaded, so we are not under suspense

  const [visibleNavs, setVisibleNavs] = useState(1);

  const navListRef = useRef<HTMLUListElement>(null);
  const listItemRef = useRef<HTMLAnchorElement>(null);
  const moreItemsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // does not blink. opt for useLayoutEffect?
    const measureItems = () => {
      if (navListRef.current && listItemRef.current && moreItemsRef.current) {
        const navListWidth = navListRef.current.offsetWidth;
        const moreMenuWidth = moreItemsRef.current.offsetWidth;
        const listItemWidth = listItemRef.current.offsetWidth;

        setVisibleNavs((navListWidth - moreMenuWidth) / listItemWidth);
      }
    };

    measureItems();
    window.addEventListener('resize', measureItems);

    return () => window.removeEventListener('resize', measureItems);
  }, [navigationLinks]);

  return (
    <Footer {...rest}>
      <NavigationMenuList as="nav" ref={navListRef}>
        {navigationLinks.length && (
          <ListItem
            ref={listItemRef}
            key={navigationLinks[0].path}
            button
            component={SideMenuLink}
            to={navigationLinks[0].path}
            exact={navigationLinks[0].path === '/' ? true : undefined}
            disableGutters
          >
            {navigationLinks[0].icon && (
              <ListItemIcon>{navigationLinks[0].icon}</ListItemIcon>
            )}
          </ListItem>
        )}

        {navigationLinks.slice(1, visibleNavs).map(({ path, icon }) => (
          <ListItem
            key={path}
            button
            component={SideMenuLink}
            to={path}
            exact={path === '/' ? true : undefined}
            disableGutters
          >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
          </ListItem>
        ))}
        {visibleNavs > 0 && (
          <MoreMenuItems
            ref={moreItemsRef}
            navigationLinks={navigationLinks.slice(
              visibleNavs,
              navigationLinks.length
            )}
          />
        )}
      </NavigationMenuList>
    </Footer>
  );
};

export default FooterMenu;
