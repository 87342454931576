import { FC, ReactElement, ReactNode } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@material-ui/core';

export interface SelectOption {
  value: string | number;
  name: string;
}

type SelectOptionProps = {
  options: SelectOption[];
  size?: 'small' | 'medium';
};

type SelectValueProps =
  | {
      multiple: false;
      onChange(value: SelectOption['value']): void;
      value: SelectOption['value'];
    }
  | {
      multiple: true;
      onChange(value: SelectOption['value'][]): void;
      value: SelectOption['value'][];
    };

export type SelectProps = { helperText?: ReactNode } & SelectOptionProps &
  SelectValueProps &
  Omit<MuiSelectProps, 'onChange' | 'value' | 'multiple'>;

const Select: FC<SelectProps> = ({
  value,
  onChange,
  options,
  multiple = false,
  variant = 'outlined',
  id = 'select-control',
  label,
  size,
  helperText,
  required,
  disabled,
  error,
  ...rest
}): ReactElement => {
  return (
    <FormControl
      variant={variant}
      size={size}
      error={error}
      disabled={disabled}
      required={required}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <MuiSelect
        {...rest}
        error={error}
        disabled={disabled}
        required={required}
        label={label}
        labelId={`${id}-label`}
        variant={variant}
        id={id}
        multiple={multiple}
        value={value ?? ''}
        onChange={(event) => {
          onChange(
            event.target.value as (string | number) & (string | number)[]
          );
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
