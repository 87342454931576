import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
`;

const IconContent = styled(DialogContent)`
  margin-top: 0.5rem;
  padding: 0;
`;

const StyledDialogTitle = styled(DialogTitle)`
  ${(props: { icon?: React.ReactNode }) =>
    `padding-top: ${props.icon ? '0.75rem' : '2.5rem'}; 
`};
  padding-bottom: 0.5rem;
`;

const DescriptionContent = styled(DialogContent)`
  padding-bottom: 0;
`;

type ButtonDialogProps = {
  open: boolean;
  title?: string;
  description?: React.ReactNode;
  icon?: React.ReactNode;
  buttons: React.ReactNode;
  onClose?: () => void;
  closeIcon?: boolean;
};

const ButtonDialog = ({
  open,
  title,
  description,
  buttons,
  onClose,
  icon,
  closeIcon,
}: ButtonDialogProps) => {
  const { t } = useTranslation('common');
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {closeIcon ? (
        <CloseButton
          size="small"
          aria-label={t('ButtonDialog.CloseButtonDialogButton')}
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </CloseButton>
      ) : null}
      {icon ? (
        <IconContent id={!description ? 'dialog-description' : undefined}>
          {icon}
        </IconContent>
      ) : null}
      <StyledDialogTitle icon={icon} id="dialog-title" disableTypography>
        {title}
      </StyledDialogTitle>
      <DescriptionContent>
        {description ? (
          <DialogContentText id="dialog-description">
            {description}
          </DialogContentText>
        ) : null}
      </DescriptionContent>
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  );
};

export default ButtonDialog;
