import { FC, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import { SelectOption } from 'components/controls/select/Select';
import { FormSelect } from 'components/form';

type FormSelectInstrumentLanguagesProps = {
  name: string;
  languages: string[];
  disabled?: boolean;
  onChange?: () => void;
  onClick?: () => void;
  instrumentId?: number;
  withLabel?: boolean;
};

const FormInstrumentLanguagesSelect: FC<FormSelectInstrumentLanguagesProps> = ({
  name,
  languages,
  disabled,
  onChange,
  onClick,
  instrumentId,
  withLabel,
}): ReactElement => {
  const { t } = useTranslation(['common', 'customer']);

  const languageOptions = languages.map((language) => ({
    value: language,
    name: language ? t(`Languages.${language.toLowerCase()}`) : '',
  }));

  const [, meta, helpers] = useField<SelectOption['value']>(name);

  const { setValue } = helpers;
  const { value } = meta;

  useEffect(() => {
    if (!disabled && languageOptions[0] && value === '') {
      setValue(languageOptions[0].value);
    }
  }, [value, languageOptions, disabled, setValue, name, instrumentId]);

  return (
    <FormSelect
      onClick={onClick}
      disabled={disabled || !languages.length}
      name={name}
      label={withLabel && t(`customer:Batches.Form.Language`)}
      size="small"
      options={languageOptions}
      onChange={onChange}
    />
  );
};

export default FormInstrumentLanguagesSelect;
