import { createContext, FC, useEffect, useReducer } from 'react';
import { getCurrentUser } from 'apis/auth';
import { AuthAction, AuthState } from 'types/auth';

import FullScreenLoading from 'components/page/FullScreenLoading';

const initialAuthState: AuthState = {
  user: null,
  isLoading: true,
};

const AuthReducer = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS': {
      return {
        ...state,
        user: action.payload,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        user: null,
      };
    }
    case 'CURRENTUSER_LOADING': {
      return {
        ...state,
        user: null,
        isLoading: true,
      };
    }
    case 'CURRENTUSER_SUCCESS': {
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      };
    }
    case 'CURRENTUSER_ERROR': {
      return {
        ...state,
        user: null,
        isLoading: false,
      };
    }
  }
};

export const AuthContext = createContext<AuthState>(initialAuthState);
export const AuthDispatchContext = createContext((action: AuthAction) => {});

export const AuthProvider: FC = ({ children }) => {
  const [authState, dispatch] = useReducer(AuthReducer, initialAuthState);

  useEffect(() => {
    (async () => {
      dispatch({ type: 'CURRENTUSER_LOADING' });

      try {
        const user = await getCurrentUser();
        dispatch({ type: 'CURRENTUSER_SUCCESS', payload: user });
      } catch (e) {
        dispatch({ type: 'CURRENTUSER_ERROR' });
      }
    })();
  }, []);

  return (
    <>
      <AuthContext.Provider value={authState}>
        <AuthDispatchContext.Provider value={dispatch}>
          {authState.isLoading ? <FullScreenLoading /> : children}
        </AuthDispatchContext.Provider>
      </AuthContext.Provider>
    </>
  );
};
