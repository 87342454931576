import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import logoFull from 'assets/images/logo-full.svg';
import logoShort from 'assets/images/logo-short.svg';
import { colors } from 'styles';

const AppLogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  background: ${colors.background};
`;

const ImageLogo = styled.img<{ $visible: boolean }>`
  ${({ $visible }) => ($visible ? `display: block;` : `display none;`)}
  width: 147px;
  height: 33px;
`;

const ShortImageLogo = styled.img<{ $visible: boolean }>`
  ${({ $visible }) => ($visible ? `display: block;` : `display none;`)}
  width: 31px;
  height: 31px;
  margin: 1px;
`; // dirty hacks

type AppLogoProps = {
  short?: boolean;
};

const AppLogo: FC<
  AppLogoProps & React.ComponentPropsWithoutRef<typeof AppLogoWrapper>
> = ({ short, ...rest }) => {
  const { t } = useTranslation('common');

  return (
    <AppLogoWrapper {...rest}>
      <ImageLogo
        $visible={!short}
        src={logoFull}
        alt={t('Menu.LogoImage')}
        draggable="false"
      />
      <ShortImageLogo
        $visible={!!short}
        src={logoShort}
        alt={t('Menu.LogoImage')}
        draggable="false"
      />
    </AppLogoWrapper>
  );
};

export default AppLogo;
