import { FC } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import useCurrentUser from 'hooks/useCurrentUser';
import { paths } from 'constants/paths';
import { Role } from 'types/auth';

type PrivateRouteProps = RouteProps & {
  redirectPath?: string;
  role?: Role;
};

const PrivateRoute: FC<PrivateRouteProps> = ({
  redirectPath,
  role,
  ...rest
}) => {
  const { user } = useCurrentUser();
  const validRole = !role || role === user?.role;

  if (user && validRole) {
    return <Route {...rest} />;
  } else {
    return <Redirect to={{ pathname: redirectPath || paths.app.login }} />;
  }
};

export default PrivateRoute;
