import { useEffect, useState, FC, createContext } from 'react';

import useCurrentUser from 'hooks/useCurrentUser';
import useLogin from 'hooks/useLogin';
import {
  AdministratorInformation,
  CustomerUserInformation,
} from 'types/userInfo';
import { Role } from 'types/auth';
import { getAdministratorInfo, getCustomerUserInfo } from 'apis/userInfo';
import { useShowAlert } from 'hooks/useShowAlert';

import FullScreenLoading from 'components/page/FullScreenLoading';

type UserInfo = AdministratorInformation | CustomerUserInformation | null;

const getUserInfoByRole = async (role: Role) => {
  switch (role) {
    case 'Administrator':
      return await getAdministratorInfo();
    case 'Customer':
      return await getCustomerUserInfo();
  }
};

export const UserInfoContext = createContext<UserInfo>(null);

export const UserInfoProvider: FC = ({ children }) => {
  const [userInfo, setInfo] = useState<UserInfo>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const { user } = useCurrentUser();
  const { showError } = useShowAlert();
  const { logout } = useLogin();

  useEffect(() => {
    let errorTimeout: ReturnType<typeof setTimeout>;
    (async () => {
      if (user) {
        try {
          setLoading(true);
          const userInfo = await getUserInfoByRole(user.role);
          setInfo(userInfo);
          setLoading(false);
        } catch (e) {
          showError(e);
          setInfo(null);

          errorTimeout = setTimeout(async () => {
            await logout();
            setLoading(false);
          }, 3000);
        }
      }
    })();

    return () => {
      errorTimeout && clearTimeout(errorTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <UserInfoContext.Provider value={userInfo}>
      {!isLoading ? children : <FullScreenLoading />}
    </UserInfoContext.Provider>
  );
};
