import { borderRadius } from './borders';
import { colors } from './colors';

export const neatPseudoShadow = `
  position: relative;

  &:before,
  &:after {
    content: '';
    border-radius: ${borderRadius};
    position: absolute;
    background-color: transparent;
  }

  &:before {
    top: 0;
    bottom: 0;
    left: 0.5rem;
    right: 0.5rem;
    z-index: -1;
    box-shadow: 0 0.5rem 0 0 ${colors.decorativeShadow.firstLayer};
  }

  &:after {
    top: 0;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    z-index: -2;
    box-shadow: 0 1rem 0 0 ${colors.decorativeShadow.secondLayer};
  }
`;

export const basicShadow = `
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
`;
