import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FormTextField } from 'components/form';
import Button from 'components/controls/button/Button';

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 1rem 0 1rem;
`;

const StyledButton = styled(Button)`
  margin: 0 1rem 0 1rem;
`;

const CityInfo = styled.div`
  display: flex;
  gap: 1rem;
  & :nth-child(1) {
    flex: 2;
  }
  & :nth-child(2) {
    flex: 1;
  }
`;

const RegistrationCompanyForm: FC = (): ReactElement => {
  const { t } = useTranslation('common');
  return (
    <>
      <InputSection>
        <FormTextField
          required
          name="organizationName"
          label={t('Registration.CompanyNameLabel')}
          size="small"
          autoComplete="organization"
        />
        <FormTextField
          required
          name="vatId"
          label={t('Registration.VatIdLabel').toUpperCase()}
          size="small"
        />
        <FormTextField
          required
          name="address"
          label={t('Registration.AddressLabel')}
          size="small"
          autoComplete="street-address"
        />
        <CityInfo>
          <FormTextField
            required
            name="city"
            label={t('Registration.CityLabel')}
            size="small"
          />
          <FormTextField
            required
            name="zipCode"
            label={t('Registration.ZipLabel').toUpperCase()}
            size="small"
            autoComplete="postal-code"
          />
        </CityInfo>
        <FormTextField
          required
          name="phone"
          label={t('Registration.PhoneNumberLabel')}
          size="small"
          autoComplete="tel"
        />
        {/* <FormTextField
          name="fax"
          label={t('Registration.FaxLabel')}
          size="small"
        /> */}
        <FormTextField
          required
          name="email"
          label={t('Registration.CompanyEmailLabel')}
          size="small"
        />
      </InputSection>
      <StyledButton type="submit">
        {t('Registration.NextButtonLabel')}
      </StyledButton>
    </>
  );
};

export default RegistrationCompanyForm;
