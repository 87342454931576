import { Credentials, CurrentUser, LoginResult } from 'types/auth';
import { get, post } from 'apis/api';

async function getCurrentUser() {
  const response = await get('/authentication/current');
  return (await response.json()) as CurrentUser;
}

async function login(credentials: Credentials) {
  const response = await post('/authentication/logon', credentials);
  return (await response.json()) as LoginResult;
}

async function logout() {
  await get('/authentication/logout');
}

export { getCurrentUser, login, logout };
